.box {
    position: relative;
    border-radius: var(--mantine-radius-default);
    border: 1px solid var(--mantine-color-dark-1);
    padding: var(--mantine-spacing-xs);
}

.setting {
    position: absolute;
    top: 0;
    right: 0;
}
