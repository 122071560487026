.grid {
    display: grid;
    min-height: 100vh;
    padding: var(--mantine-spacing-md);
    gap: var(--mantine-spacing-lg);
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr 50px;
    grid-template-areas:
        "header"
        "nav"
        "main"
        "footer";

    > header {
        grid-area: header;
    }
    > main {
        grid-area: main;
    }
    > footer {
        grid-area: footer;
    }

    @media (min-width: 700px) {
        grid-template-rows: auto 1fr 50px;
        grid-template-columns: 200px 1fr;
        grid-template-areas:
            "header header"
            "nav main"
            "footer footer";
    }

    @media (min-width: 800px) {
        grid-template-columns: 300px 1fr;
    }
}

.nav {
    grid-area: nav;
    display: grid;
    gap: var(--mantine-spacing-sm);
    padding: var(--mantine-spacing-xs);
    align-content: flex-start;
    box-shadow: var(--mantine-shadow-md);
}

.footer {
    justify-self: center;
    align-self: center;
}
