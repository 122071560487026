.grid {
    display: grid;
    gap: var(--mantine-spacing-md);
}

.imageGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-auto-rows: calc(300px / 1.75);
    gap: var(--mantine-spacing-sm);
}
