.grid {
    display: grid;
    gap: var(--mantine-spacing-md);
    max-width: 800px;
}

.chunkNode {
    padding: var(--mantine-spacing-md) 0;
    white-space: "pre-wrap";
    border-bottom: 1px solid var(--mantine-color-gray-5);

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }
}

.icons {
    position: absolute;
    top: 0;
    right: 0;
}

.markdownText {
    img {
        max-width: 100%;
    }
}
