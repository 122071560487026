.grid {
    display: grid;
    gap: var(--mantine-spacing-md);
    max-width: 800px;
}

.icons {
    position: absolute;
    top: 0;
    right: 0;
}
