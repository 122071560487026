.border {
    border-top: 1px solid grey;
    margin: var(--mantine-spacing-sm) 0;
}

.loader {
    display: flex;
    align-items: center;
    gap: var(--mantine-spacing-md);
}

.clickDivider {
    cursor: pointer;
}
