.scrollRow {
    display: grid;
    gap: var(--mantine-spacing-md);
    grid-auto-columns: 500px;
    grid-auto-flow: column;
}

.scrollTainer {
    max-width: calc(100vw - 300px - var(--mantine-spacing-md) - var(--mantine-spacing-md) - var(--mantine-spacing-lg));
    overflow-x: auto;
    padding-bottom: var(--mantine-spacing-md);
}
