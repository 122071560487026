.markdown {
    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
    min-width: 0;
}

.markdown pre {
    max-width: 100%;
    overflow-x: auto;
}