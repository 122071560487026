.grid {
    display: grid;
    gap: var(--mantine-spacing-md);
}
.personalityGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: var(--mantine-spacing-md);
}

.box {
    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 2;
    border: 1px solid var(--mantine-color-dark-1);
    padding: var(--mantine-spacing-md);
    border-radius: var(--mantine-radius-default);
    display: grid;
    gap: var(--mantine-spacing-sm);
}
