.result {
    display: block;
    max-width: 800px;
    color: var(--mantine-color-black);
    text-decoration: none;

    h3 {
        color: var(--mantine-color-blue-6);
    }
}
