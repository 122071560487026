.infoNode {
    position: relative;
    border: 1px solid var(--mantine-color-gray-5);
    border-radius: var(--mantine-radius-md);
    padding: var(--mantine-spacing-md);
    margin-top: var(--mantine-spacing-sm);
}

.nodeTitle {
    font-size: var(--mantine-font-size-lg);
    position: absolute;
    top: -14px;
    background-color: var(--mantine-color-white);
    padding: 0 5px;
    font-weight: normal;
}
