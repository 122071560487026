.chatBox {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: var(--mantine-spacing-sm);
    border-radius: var(--mantine-radius-default);
    border: 1px solid var(--mantine-color-dark-1);
    padding: var(--mantine-spacing-xs);
}

.userInput {
    white-space: pre-wrap;
}

.icons {
    position: absolute;
    top: 0;
    right: 0;
}
